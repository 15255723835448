.header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.content {
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: 25px;
}

.info {
  font-size: 14px;
  color: #52576F;
  margin-bottom: 12px;
  span {
    font-size: 14px;
    margin-right: 11px;
    color: #07C0CA;
    position: relative;
    top: 2px;
  }
}

.name_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}
.name {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.street_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

.street {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.phone_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}

.phone {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}

.email_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 4;
}

.email {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.ein_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 5;
  grid-row-end: 5;
}

.ein {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 5;
  grid-row-end: 5;
}

.acc_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 6;
  grid-row-end: 6;
}

.acc {
  @extend .info;
  grid-column-start: 2;
  grid-row-start: 6;
  grid-row-end: 6;
}

.mdr_ico {
  @extend .info;
  grid-column-start: 1;
  grid-row-start: 7;
  grid-row-end: 7;
}

.mdr {
  font-size: 14px;
  color: #52576F;
  margin-bottom: 12px;
  grid-column-start: 2;
  grid-row-start: 7;
  grid-row-end: 7;
}

.modalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.modalHeader {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #31364C;
}

.modalParagraph {
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.modalSubParagraph {
  text-align: left;
  font-weight: bold;
  margin-top: 25px;
  color: #31364C;
}

.modalBlueHorizontalBar {
  position: relative;
  background: #07C0CA;
  padding: 1% 5% 1% 4%;
  margin-right: 3%;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.modalOrangeHorizontalBar {
  position: relative;
  background: #FFAE1A;
  padding: 1% 19% 1% 4%;
  margin-right: 3%;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.modalRedHorizontalBar {
  position: relative;
  background: #FE6E65;
  padding: 1% 50% 1% 4%;
  margin-right: 3%;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.modalPercentage {
  font-size: 12px;
}