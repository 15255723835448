.logo {
    display: block;
    margin: 0 auto;
}

.modalBody {
    width: 255px;
    margin-top: -15px;
    z-index: 100;

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.27px;
        color: #31364C;
    }

    .description {
        font-family: 'Montserrat';
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #52576F;
    }
}

.transactionPayoutPercentsHint {
    font-size: 12px;
    color: #07C0CA;
    margin-top: 6px;
}

.resetPayouts {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #07C0CA;
    margin: 8px 0;
    cursor: pointer;
    span {
        font-size: 16px;
        margin-left: 6px;
        color: #07c0ca;
        position: relative;
        top: 3px;
        right: 3px;
    }
}

.button {
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 15px 0px 0px !important;
}

.buttonDisabled {
    @extend .button;
    background-color: #d6d8e1 !important;
}

.buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
}