.title {
    font-size: 18px;
    margin-top: 25px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
}

.headerText {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.337647px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.message {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    text-align: left;
    color: #787e9c;
    padding: 15px 0;
}

.button {
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 0px !important;
  }
  
  .buttonDisabled {
    @extend .button;
    background-color: #d6d8e1 !important;
  }
  
  .buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
  }
  
  button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
  }
  
  a:hover {
    color: #006f7b !important;
  }


.rateInfo {
  font-size: 12px;
  color: #07C0CA;
  margin-top: 8px;
  cursor: pointer;
  span {
    font-size: 16px;
    margin-left: 6px;
    color: #07c0ca;
    position: relative;
    top: 3px;
    right: 3px;
  }
}

.addPayouts {
  font-size: 12px;
  font-weight: 700;
  color: #07C0CA;
  margin-top: 8px;
  cursor: pointer;
  span {
    font-size: 16px;
    margin-left: 6px;
    color: #07c0ca;
    position: relative;
    top: 3px;
    right: 3px;
  }
}

.transactionAmountHint {
  font-size: 12px;
  color: #07C0CA;
  margin-top: 6px;
}